import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

class InspectHistoryItemModel {
  constructor(obj) {
    this.regDate = null;
    this.stateCode = "";
    this.etc = "";
    this.regId = "";
    this.contractId = "";
    this.calculateNum = null;
    this.calculateDate = null;
    this.inspectCompleteDate = null;
    this.deliveryCompleteDate = null;
    this.deliveryHistory = "";
    this.stateCodeName = "";

    if (obj) {
      this.setData(obj);
    }
  }

  setData(obj) {
    if (obj.regDate) this.regDate = stringToDateWithTime(obj.regDate);
    if (obj.stateCode) this.stateCode = obj.stateCode;
    if (obj.etc) this.etc = obj.etc;
    if (obj.regId) this.regId = obj.regId;
    if (obj.contractId) this.contractId = obj.contractId;
    if (obj.calculateNum) this.calculateNum = obj.calculateNum;
    if (obj.calculateDate) this.calculateDate = obj.calculateDate;
    if (obj.inspectCompleteDate)
      this.inspectCompleteDate = stringToDateWithTime(obj.inspectCompleteDate);
    if (obj.deliveryCompleteDate)
      this.deliveryCompleteDate = stringToDateWithTime(obj.deliveryCompleteDate);
    if (obj.deliveryHistory) this.deliveryHistory = obj.deliveryHistory;
    if (obj.stateCodeName) this.stateCodeName = obj.stateCodeName;
  }

  getData() {
    let obj = {
      stateCode: this.stateCode,
      etc: this.etc,
      regId: this.regId,
      contractId: this.contractId,
      calculateNum: this.calculateNum,
      calculateDate: this.calculateDate,
      inspectCompleteDate: dateToStringWithTime(this.inspectCompleteDate),
      deliveryCompleteDate: dateToStringWithTime(this.deliveryCompleteDate),
      deliveryHistory: this.deliveryHistory,
      stateCodeName: this.stateCodeName,
    };

    return obj;
  }
}

export default InspectHistoryItemModel;
