import ContractInspectItemModel from "./ContractInspectItemModel";

// class InspectHistoryModel {
//     constructor(obj) {
//        // this.inspectHistory = [];
//        this.objRaw = {};

//        this.objItemData = {};

//        this.regDate = null;
//        this.stateCode = '';
//        this.etc = '';
//        this.regId = '';
//        this.contractId = '';
//        this.calculateNum = null;
//        this.inspectCompleteDate = null;
//        this.deliveryCompleteDate= null;
//        this.deliveryHistory = '';
//        this.stateCodeName = '';

//        if(obj) {
//            this.objItemData = obj;
//        }
//     }

//     setItemData(obj) {
//         this.objRaw = obj;
//     }

//     setData(obj) {
//         this.objRaw = obj;

//         if(obj.regDate) this.regDate = stringToDateWithTime(obj.regDate);
//         if(obj.stateCode) this.stateCode = obj.stateCode;
//         if(obj.etc) this.etc = obj.etc;
//         if(obj.regId) this.regId = obj.regId;
//         if(obj.contractId) this.contractId = obj.contractId;
//         if(obj.calculateNum) this.calculateNum = obj.calculateNum;
//         if(obj.inspectCompleteDate) this.inspectCompleteDate = stringToDateWithTime(obj.inspectCompleteDate);
//         if(obj.deliveryCompleteDate) this.deliveryCompleteDate = stringToDateWithTime(obj.deliveryCompleteDate);
//         if(obj.deliveryHistory) this.deliveryHistory = obj.deliveryHistory;
//         if(obj.stateCodeName) this.stateCodeName = obj.stateCodeName;

//     }

//     getData() {
//        // const ret = this.inspectHistory.map( item => item.getData() ) || [];

//        // return ret;
//        return Object.assign({}, this.objItemData, this.objRaw);
//     }

// }

class ContractInspectModel {
  constructor() {
    this.contractInspect = [];
  }

  setData(obj) {
    this.contractInspect =
      obj.map((item) => {
        const itemModel = new ContractInspectItemModel();
        itemModel.setData(item);
        return itemModel;
      }) || [];
  }

  getData() {
    const ret = this.contractInspect.map((item) => item.getData()) || [];

    return ret;
  }
}

export default contractInspect;
